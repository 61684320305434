var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("车辆数据库")]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.add(0, "")
                  },
                },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
              _c("el-table-column", { attrs: { prop: "name", label: "库名" } }),
              _c("el-table-column", {
                attrs: { label: "是否激活" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status
                          ? _c("span", [_vm._v("已激活")])
                          : _c("span", [_vm._v("未激活")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtCreated", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtUpdated", label: "上次修改时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.add(1, scope.row)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteItem(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
      _c("car-reco-data-add", {
        attrs: { currentItem: _vm.currentItem, isModify: _vm.isModify },
        on: { handleAdd: _vm.handleAdd },
        model: {
          value: _vm.addDialogVisible,
          callback: function ($$v) {
            _vm.addDialogVisible = $$v
          },
          expression: "addDialogVisible",
        },
      }),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }